// @ts-check
import { features as staticFeatures } from "@sciam/chargebee/constants.json";
import { createContext, useContext, useEffect, useState } from "react";

import { useSciAmJwtSync } from "~features/piano/hooks/use-piano-auth-sync";
import { useUser } from "~features/user";
import { getInstitutionalAccess } from "./institutional";
import { log } from "./utils";

const AccessContext = createContext(
  /** @type {UserAccessContext} */ ({
    ready: false,
    hasSub: undefined,
    hasDigitalAccess: undefined,
    hasSpecialEditionAccess: undefined,
    hasAdFree: undefined,
    hasInstitutionalAccess: undefined,
    features: [],
    featureIds: [],
    entitlements: [], // @TODO remove this. it's piano resource IDs and misnamed
  }),
);

/**
 * This hook merges user entitlements with institutional access
 * and provides a single source of truth for the user's current access state.
 *
 * To check for other features not explicitly provided like print editions or shared subscriptions,
 * you can use the `features` or `featureIds` arrays to check for the presence of a feature.
 *
 * @example ```js
 * const { featureIds } = useEntitlements();
 * const getsPrintIssues = featureIds.includes("print-issues");
 * ```
 * @returns {UserAccessContext}
 */
export function useEntitlements() {
  return useContext(AccessContext);
}

// Create the provider component
export function AccessProvider({ children }) {
  const value = useProvideEntitlements();
  return <AccessContext.Provider value={value}>{children}</AccessContext.Provider>;
}

function useProvideEntitlements() {
  const { featureIds: userFeatureIds, isActiveSubscriber, activePlans } = useUser();
  const { ready: isSciAmJwtReady } = useSciAmJwtSync();
  const { hasInstitutionalAccess } = useProvideInstitutionalAccess();

  // Merge user's feature list with institutional access features
  const getFeatureObjects = (ids) => staticFeatures.filter(({ id }) => ids.includes(id));
  const [featureIds, setFeatureIds] = useState(userFeatureIds);
  const [features, setFeatures] = useState(getFeatureObjects(userFeatureIds));
  useEffect(() => {
    const newFeatureIds = hasInstitutionalAccess
      ? [...new Set([...userFeatureIds, "digital-access", "special-edition-access"])]
      : userFeatureIds;
    const newFeatures = getFeatureObjects(newFeatureIds);

    // Update state
    setFeatureIds(newFeatureIds);
    setFeatures(newFeatures);
  }, [hasInstitutionalAccess, userFeatureIds]);

  // Legacy Entitlements List - outputs Piano Resource IDs
  const getActivePianoResources = () => [
    ...new Set(activePlans.map((plan) => plan.legacy_resource_id)),
  ];
  const [entitlements, setEntitlements] = useState(getActivePianoResources);
  useEffect(() => setEntitlements(getActivePianoResources), [activePlans]);

  // Computed Entitlements State
  // undefined until we have data to work with
  let hasSub;
  let hasDigitalAccess = hasInstitutionalAccess;
  let hasSpecialEditionAccess = hasInstitutionalAccess;
  let hasAdFree;

  if (isSciAmJwtReady) {
    hasSub = isActiveSubscriber;
    hasDigitalAccess ||= featureIds?.includes("digital-access");
    hasSpecialEditionAccess ||= featureIds?.includes("special-edition-access");
    hasAdFree = featureIds?.includes("ad-free-access");
  }

  // Ready when the JWT is settled AND
  // User has Unlimited OR we know their institutional access state
  const ready =
    isSciAmJwtReady && (hasSpecialEditionAccess || typeof hasInstitutionalAccess !== "undefined");

  return {
    ready,
    hasDigitalAccess,
    hasSpecialEditionAccess,
    hasSub,
    hasAdFree,
    hasInstitutionalAccess,
    features,
    featureIds,
    plans: activePlans,

    // Piano-era values
    hasUnlimitedAccess: hasSpecialEditionAccess,
    hasAccess: hasDigitalAccess,
    entitlements,
  };
}

/**
 * TODO: This might be better translated to TS
 *
 * @typedef {{
 *  hasSub: boolean | undefined,
 *  hasDigitalAccess: boolean | undefined,
 *  hasSpecialEditionAccess: boolean | undefined,
 *  hasAdFree: boolean | undefined,
 * }} AccessState
 *
 * @typedef {{
 *   featureIds: string[],
 *   features: typeof staticFeatures,
 *   plans: ReturnType<useUser>["plans"],
 * }} EntitlementState
 *
 * @typedef {{
 *   hasInstitutionalAccess: boolean | undefined,
 * }} InstitutionalAccessState
 *
 * @typedef {{
 *  hasUnlimitedAccess: boolean | undefined,
 *  hasAccess: boolean | undefined,
 *  entitlements: string[],
 * }} LegacyAccessState
 *
 * @typedef {{ ready: boolean }
 *  & AccessState
 *  & EntitlementState
 *  & InstitutionalAccessState
 *  & LegacyAccessState
 * } UserAccessContext
 */

function useProvideInstitutionalAccess() {
  const [hasInstitutionalAccess, setHasInstitutionalAccess] = useState(
    /** @type {boolean|undefined} */ (undefined),
  );

  useEffect(() => {
    // Institutional details will NOT update isReady.
    // User access can overlap with institutions in strange ways
    // so in effect institutional access becomes a fallback.
    function handleInstAccessChange() {
      const idpAccess = getInstitutionalAccess();
      if (idpAccess) {
        log(`[entitlements] user has access from their Institution`);
      }
      setHasInstitutionalAccess(idpAccess);
    }

    handleInstAccessChange();
    window.addEventListener("ipd-update", handleInstAccessChange);
    return () => window.removeEventListener("idp-update", handleInstAccessChange);
  }, []);

  return { hasInstitutionalAccess };
}
